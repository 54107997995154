<script setup lang="ts">
import Documents from "~/components/icons/Documents.vue";
import Memoweb from "~/components/icons/Memoweb.vue";
import { link } from "~/lib/utils/contents";

const omniaSdk = useOmniaSdk();

useHead({
  title: "Progetto Omnia, il portale per gli Enti Locali",
});

const { data: areas } = await useAsyncData("all-areas", async () => {
  try {
    return await omniaSdk.v1.areas.list({ pagination: "none" });
  } catch (error) {}
});

const { data: contents, status: statusContents } = useAsyncData("index-contents", async () => {
  try {
    return await omniaSdk.v1.contents.all({}, { pagination: "cursor" }, { types: ["notizie", "modelli", "formazione"] });
  } catch (error) {}
});
</script>

<template>
  <!-- RICERCA -->
  <div class="relative w-full">
    <NuxtImg
      provider="cloudflare"
      src="/images/background.png"
      format="webp"
      sizes="400px xs:400px sm:800px md:1600px lg:1600px xl:1920px 2xl:2500px"
      class="absolute inset-0 w-full h-full object-cover"
      alt="Background"
    />
    <!-- <NuxtImg
      src="/images/background-fore.png"
      format="webp"
      sizes="400px xs:400px sm:800px md:1600px lg:1600px xl:1920px 2xl:2500px"
      class="hidden md:block absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[1205px] h-auto"
      alt="Background"
    /> -->
    <div class="relative py-5 md:py-8 p-1 sm:px-8 text-primary-foreground bg-black bg-opacity-10">
      <div class="text-center max-w-3xl mx-auto">
        <h2 class="text-3xl sm:text-4xl lg:text-5xl font-extrabold">Benvenuto in Progetto Omnia</h2>
        <p class="mt-5 text-xl lg:text-2xl">Informazione, operatività, approfondimento normativo e consulenza per ogni singolo ufficio comunale</p>
      </div>
      <div class="bg-white bg-opacity-80 max-w-3xl mt-5 md:mt-8 mx-auto rounded px-2 py-5 sm:px-5 shadow-lg shadow-primary-950/10">
        <FrontSearchModal></FrontSearchModal>
      </div>
    </div>
  </div>

  <!-- AREE -->
  <div class="main-content text-center py-12">
    <h2 class="flex items-center justify-center text-4xl font-extrabold mb-5"><IconsMunicipality class="text-themeGray w-12 h-12 me-2"></IconsMunicipality>Scegli il tuo ufficio</h2>
    <p>Strumenti operativi, approfondimenti e informazioni sempre aggiornate per ogni singolo ufficio comunale.</p>
    <FrontAreas v-if="areas?.data" :areas="areas?.data" />
  </div>

  <!-- INFORMAZIONI DAL MEMOWEB -->
  <FrontContentsSection title="Informazioni dal Memoweb" :icon="Memoweb" cta-link="/notizie" cta-title="Vedi tutto" :background="true" mode="cards">
    <template #abstract>
      L’informazione quotidiana dei Memo e tutti gli approfondimenti delle Note Operative e delle Video Note: indicazioni puntuali e sempre aggiornate a supporto delle attività dell’ente.
    </template>
    <template #default>
      <Skeleton v-if="statusContents === 'pending'" v-for="i in Array(3).keys()" class="w-full h-80 bg-primary/25" />
      <FrontContentCard v-else v-for="content in contents?.notizie" :content="content" :key="`notizie-${content.id}`" :link-config="{}" />
    </template>
  </FrontContentsSection>

  <!-- Modulistica -->
  <FrontContentsSection title="Operatività" :icon="Documents" :background="false" mode="items" position="right">
    <template #abstract>Modulistica compilabile per l’attuazione degli adempimenti: determine, delibere, verbali e oltre 13.000 modelli, costantemente aggiornati e pronti da utilizzare.</template>
    <template #default>
      <Skeleton v-if="statusContents === 'pending'" v-for="i in Array(3).keys()" class="w-full h-44" />
      <FrontContentItem v-else v-for="content in contents?.modelli" :compact="false" :show-children="false" :content="content" :show-areas="true" :key="`modelli-${content.id}`" :link-config="{}" />
    </template>
  </FrontContentsSection>

  <!-- Consulenza -->
  <div class="bg-primary text-white pt-12 pb-20">
    <div class="main-content">
      <h2 class="flex items-center text-4xl font-extrabold mb-5"><IconsChat class="w-12 h-12 me-2"></IconsChat>Consulenza</h2>
      <p class="mb-8">Tutta l’esperienza dei consulenti Gaspari a supporto delle attività quotidiane dell’ente.</p>
    </div>
  </div>
  <div class="main-content grid md:grid-cols-2 gap-8 -mt-16 mb-12">
    <div class="bg-white shadow-lg p-10">
      <h3 class="sm:flex items-center text-4xl font-extrabold mb-5"><IconsCog class="flex-none w-12 h-12 mb-2 sm:mb-0 me-2 text-primary"></IconsCog>Servizi all'Ente Locale</h3>
      <p class="mb-6">Servizi operativi e personalizzati che semplificano il lavoro e assicurano la corretta realizzazione degli adempimenti.</p>
      <div>
        <Button class="max-w-max" as-child><NuxtLink href="/omnia-servizi.pdf" target="_blank" rel="noopener noreferrer">Scopri di più</NuxtLink></Button>
      </div>
    </div>
    <div class="bg-white shadow-lg p-10">
      <h3 class="sm:flex items-center text-4xl font-extrabold mb-5"><IconsHelp class="flex-none w-12 h-12 mb-2 sm:mb-0 me-2 text-primary"></IconsHelp>Quesiti</h3>
      <p class="mb-6">Risposte chiare e operative su qualunque tematica e una banca dati di 15.000 quesiti già risolti.</p>
      <div>
        <Button class="max-w-max" as-child><NuxtLink href="/quesiti">Scopri di più</NuxtLink></Button>
      </div>
    </div>
  </div>

  <!-- FORMAZIONE -->
  <div class="bg-primary-50" v-if="useRuntimeConfig().public.formazioneUrl" id="formazione">
    <div class="main-content py-14">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div class="flex flex-col justify-center mb-5 md:mb-0">
          <h2 class="text-4xl font-extrabold mb-5"><IconsRecord class="text-themeGray w-12 h-12 mb-2"></IconsRecord>Formazione</h2>
          <p class="mb-8">
            Un nuovo portale dedicato esclusivamente alla formazione, con corsi tenuti da docenti qualificati e un pratico sistema per la gestione delle utenze, la verifica delle competenze e il
            rilascio di certificati.
          </p>
          <Button class="max-w-max" as-child><NuxtLink :href="useRuntimeConfig().public.formazioneUrl" target="_blank" rel="noopener noreferrer">Scopri di più</NuxtLink></Button>
        </div>
        <Skeleton v-if="statusContents === 'pending'" v-for="i in Array(2).keys()" class="w-full h-80 bg-primary/25" />
        <template v-else v-for="content in contents?.formazione.slice(0, 2)">
          <NuxtLink :to="link({ content })" :title="content.title">
            <img loading="lazy" :src="content.image?.original_url" :srcset="content.image?.srcset" class="w-full shadow-lg" :alt="content.title" />
          </NuxtLink>
        </template>
        <template v-for="i in Array(Math.max(2 - contents?.formazione?.length || 0, 0)).keys()">
          <img loading="lazy" :src="`/images/corso_${i + 1}.webp`" class="w-full shadow-lg" alt="Omnia formazione" />
        </template>
      </div>
    </div>
  </div>
</template>
